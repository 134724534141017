.landing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title-container {
  display: flex;
  width: 100vw;
  background-color: #ededed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

.title-button {
  background-color: #00f7da;
  color: #151035;
  margin-top: 1rem;
}

.title-button:hover {
  background-color: #00d1b8;
}

.about-us-button {
  transition: background-color 0.3s;
}

.about-us-button:hover {
  background-color: #00d1b8;
  color: #151035;
}

.body-text-color {
  background-image: linear-gradient(#00fff2, #00ff1e);
  color: transparent;
  background-clip: text;
}

.calendar-color {
  background-image: linear-gradient(#fb00ff, #6c1dff);
  color: transparent;
  background-clip: text;
}

.section-paragraph {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
}
