@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  color: #151035;
  background-color: #f5f8fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 48px;
  font-weight: 700;
}

h2 {
  font-size: 32px;
  font-weight: 700;
}

p {
  font-size: 18px;
  font-weight: 400;
  text-align: left;
}

p.footer {
  font-size: 14px;
}
